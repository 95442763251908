import * as React from "react"

import Seo from "../components/seo"
import Slider from "../components/corefilm-sleepingdogs.js"

import {Helmet} from "react-helmet";




const mainPage = ({}) => {



  return (

<div>
    <Seo
        title='Sleeping Dogs | Film | E.O. Chirovici'
        
        description='Sleeping Dogs is a film whose script has been adapted from The Book fo Mirrors, a crime fiction book written by Eugen Ovidiu Chirovici, also known as E.O. Chirovici, and who is the writer of the Book of Mirrors '

        keywords='Sleeping Dogs, Eugen Ovidiu Chirovici, E.O. Chirovici, The Book of Mirrors, Sleeping Dogs, Sleeping Dogs The Film, Sleeping Dogs Movie'

      />

<Helmet>

</Helmet>



<Slider/>
     
    </div>
  )

}


export default mainPage;