import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import Grid from '@mui/material/Grid';


import Breadcrumbs from '@mui/material/Breadcrumbs';

import "swiper/css";

import {Navigation } from "swiper";
import Paper from '@mui/material/Paper';



import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import { MdOutlineSwipeLeft } from "react-icons/md/";
import { MdOutlineSwipeRight } from "react-icons/md";










export default function App(props) {

  



  return (

      <>
  <Swiper
        className='bookPage'
        spaceBetween={0}

                centeredSlides={false}



        grabCursor={false}
  
        resistance={false}  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/talk');    };

              if (swiperCore.realIndex === 0) {    navigate('/books');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white switchToSLC books'> 





<div className='longPage'>




<span className='standardIntroductionText standardBookIntroductionText animate__animated'>


    <Breadcrumbs aria-label="breadcrumb">
        <Link className='breadcrumbLink' to="/">
          Home
        </Link>
          <Link rel='nofollow' className='breadcrumbLink' to="/sleeping-dogs">
          Film
        </Link>
        <Link rel='nofollow' className='breadcrumbLink' to="/sleeping-dogs">
          Sleeping Dogs
        </Link>




      </Breadcrumbs>



<h3  className=' animate__animated'>Sleeping Dogs</h3>

</span>


<Grid className='bookGrid' container spacing={2}>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Crime </span>


  </Grid>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>2024 </span>


  </Grid>
  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Nickel City Productions </span>


  </Grid>









  </Grid>







<span className='standardIntroductionText animate__animated'>

<p className='animate__animated'>

The Book of Mirrors has been adapted for the cinema by Adam Cooper and Bill Collage into a film produced by Nickel City Productions. It is due to be released in March 2024 and features a most humbling cast composed of Russell Crowe, Karen Gillian, Tommy Flanagan and Marton Csokas. <br/><br/>



</p>
</span>






<Grid className='actorGrid topBorder marginTop' container spacing={2}>

  <Grid item md={6} xs={6}>


<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Russell_Crowe_1999.jpg/203px-Russell_Crowe_1999.jpg'/>


   <span className='bookGridSpan'>Russell Crowe </span>


  </Grid>

  <Grid item md={6} xs={6}>

<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Karen_Gillan_%2834609339830%29_%28cropped%29_retouched.jpg/183px-Karen_Gillan_%2834609339830%29_%28cropped%29_retouched.jpg'/>

   <span className='bookGridSpan'>Karen Gillian </span>


  </Grid>


  </Grid>


<Grid className='actorGrid bottomBorder marginBottom' container spacing={2}>

  <Grid item md={6} xs={6}>

<img src='https://upload.wikimedia.org/wikipedia/commons/a/a5/Marton_Csokas_by_andrew_maccoll.jpg'/>

   <span className='bookGridSpan'>Marton Csokas </span>


  </Grid>

  <Grid item md={6} xs={6}>

<img src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Tommy_Flanagan_by_Gage_Skidmore.jpg/180px-Tommy_Flanagan_by_Gage_Skidmore.jpg'/>

   <span className='bookGridSpan'>Tommy Flanagan </span>


  </Grid>


  </Grid>



  





<video className="filmPageVideo" height='480px' width="100%" preload="auto" controls loop>


              <source src='/film/sleeping-dogs-trailer-low.mp4' type="video/mp4"/>

              <source src="/film/sleeping-dogs-trailer-low.ogg" type="video/ogg"/>


            </video>










<div className='bookNavigationButtons'>


<Link className='bookpageButton Left' to='/books'>

<MdOutlineSwipeLeft />

 Books


</Link>

<Link className='bookpageButton Right' to='/talk'>

Talk

<MdOutlineSwipeRight />


</Link>


</div>






</div>




</SwiperSlide>




























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}